@layer packages {
    .hiddenMobile {
        display: none;
    }

    .hiddenDesktop {
        display: block;
    }

    @media (min-width: 1024px) {
        .hiddenMobile {
            display: block;
        }

        .hiddenDesktop {
            display: none;
        }
    }
}
@layer packages {
    .hiddenMobile {
        display: none;
    }

    .hiddenDesktop {
        display: block;
    }

    @media (min-width: 1024px) {
        .hiddenMobile {
            display: block;
        }

        .hiddenDesktop {
            display: none;
        }
    }
}
